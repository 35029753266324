import { IStoreState } from "../../../../reducers/types";
import { createSelector } from "reselect";
import dayjs from "dayjs";
import {
  CalendarPriceBucket,
  HotelEntryProperties,
  IResult,
  ITrackingProperties,
  StatementCreditDetail,
} from "redmond";
import {
  getAgentEmail,
  getIsFirstLaunch,
  getRewardsAccounts,
  getSelectedAccount,
} from "../../../rewards/reducer/selectors";
import {
  getTravelWalletCredit,
  getCreditBreakdown,
} from "../../../travel-wallet/reducer";

export const getLocation = (state: IStoreState): IResult | null =>
  state.hotelSearch.location;

export const getLocationCategories = (state: IStoreState) =>
  state.hotelSearch.locationCategories;

export const getLocationCategoriesLoading = (state: IStoreState) =>
  state.hotelSearch.locationCategoriesLoading;

export const getFromDate = (state: IStoreState) => state.hotelSearch.fromDate;

export const getUntilDate = (state: IStoreState) => state.hotelSearch.untilDate;

export const getAdultsCount = (state: IStoreState) =>
  state.hotelSearch.adultsCount;

export const getChildrenCount = (state: IStoreState) =>
  state.hotelSearch.children.length;

export const getRoomsCount = (state: IStoreState) =>
  state.hotelSearch.roomsCount;

export const getChildren = (state: IStoreState) => state.hotelSearch.children;

export const getNightCount = createSelector(
  getFromDate,
  getUntilDate,
  (fromDate, untilDate): number | null => {
    if (fromDate && untilDate) {
      return dayjs(untilDate).diff(dayjs(fromDate), "day");
    } else {
      return null;
    }
  }
);
export const getHotelAvailEntryPoint = (state: IStoreState) =>
  state.hotelAvailability.hotelAvailEntryPoint;

export const getApplyUserHotelPreferences = (state: IStoreState) =>
  state.hotelSearch.applyUserHotelPreferences;

export const getPaymentMethods = (state: IStoreState) =>
  state.hotelAvailability.paymentMethods;

export const getHotelAvailabilityTrackingProperties = (state: IStoreState) =>
  state.hotelAvailability.availabilityResponse?.trackingPropertiesV2

export const getHotelEntryProperties = createSelector(
  getAgentEmail,
  getLocation,
  getFromDate,
  getUntilDate,
  getIsFirstLaunch,
  getRewardsAccounts,
  getAdultsCount,
  getChildrenCount,
  getRoomsCount,
  getTravelWalletCredit,
  getCreditBreakdown,
  getSelectedAccount,
  getHotelAvailEntryPoint,
  getApplyUserHotelPreferences,
  getPaymentMethods,
  getHotelAvailabilityTrackingProperties,
  (
    agentEmail,
    location,
    fromDate,
    untilDate,
    isFirstLaunch,
    rewardsAccounts,
    numAdults,
    numChildren,
    roomCount,
    credit,
    creditBreakdown,
    selectedAccount,
    hotelAvailEntryPoint,
    applyUserHotelPreferences,
    paymentMethods,
    availabilityTrackingProperties,
  ): ITrackingProperties<HotelEntryProperties> => {
    return {
      properties: {
        delegated_to: agentEmail || "",
        first_launch: isFirstLaunch,
        market: location?.label || "",
        // this is the best effort way to parse the country out of the location label
        // this value will not be correct all of the time since address are known to be difficult to parse
        // it should only be used for tracking purposes and not relied on for anything else
        country:
          location?.subLabel || location?.label?.split(", ")?.pop() || "",
        number_of_properties: 0,
        number_of_guests: numAdults + numChildren,
        hotel_advance: fromDate
          ? `${dayjs(fromDate).diff(dayjs(), "day")} days`
          : "",
        rooms_searched: roomCount,
        rewards_accounts: rewardsAccounts
          .map((r) => r.productDisplayName)
          .join(","),
        has_credits: !!credit?.amount?.amount,
        credit_balance: !!credit?.amount?.amount
          ? Math.abs(credit.amount.amount)
          : 0,
        vx_statement_credit_balance:
          creditBreakdown
            ?.filter((b) => b.CreditDetail === "Statement")
            .reduce(
              (prev, curr) =>
                prev + (curr as StatementCreditDetail).usableAmount.amount,
              0
            ) || 0,
        customer_account_role: selectedAccount?.customerAccountRole,
        entry_type: hotelAvailEntryPoint,
        los:
          fromDate && untilDate
            ? `${dayjs(untilDate).diff(fromDate, "day")} days`
            : undefined,
        preferences_applied: applyUserHotelPreferences,
        card_on_file: paymentMethods.length > 0,
        ...location?.trackingPropertiesV2?.properties,
        ...availabilityTrackingProperties?.properties,
      },
      encryptedProperties: [
        location?.trackingPropertiesV2?.encryptedProperties ?? "",
        availabilityTrackingProperties?.encryptedProperties ?? "",
      ],
    };
  }
);

export const getHasLocationAutocompleteError = (state: IStoreState) =>
  state.hotelSearch.hasLocationAutocompleteError;

export const getCalendarPriceBuckets = (state: IStoreState) =>
  state.hotelSearch.calendarPriceBuckets;

export const getCalendarPriceRangeLegends = createSelector(
  [getCalendarPriceBuckets],
  (calendarPriceBuckets) => {
    const legends = calendarPriceBuckets
      ? calendarPriceBuckets.map((bucket: CalendarPriceBucket) =>
          bucket.legend.toString()
        )
      : [];
    return legends;
  }
);

export const getCalendarPriceMonths = (state: IStoreState) =>
  state.hotelSearch.calendarPriceMonths;
