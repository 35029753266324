import React, { useContext, useEffect, useMemo, useState } from "react";
import { Box, Chip, Divider, Typography } from "@material-ui/core";
import {
  CallState,
  TravelProductEnum,
  TokenizeCardErrors,
  CustomerAccountRole,
  CorpSessionInfo,
} from "redmond";
import {
  GenericInfoPopup,
  B2BSpinner,
  LoadingIndicator,
  useDeviceTypes,
  LoadingPopup,
  PoweredByHopper,
  CheckoutPaymentForm,
  B2BPaymentMethodSelectWorkflow,
  Icon,
  IconName,
  createElementId,
  TEST_CARD_LAST_FOURS,
  NotificationBanner,
  BannerSeverity,
} from "halifax";
import clsx from "clsx";
import { useExperimentIsVariant } from "@capone/experiments";
import { isCorpTenant, OUT_OF_POLICY_REWARDS_BANNER } from "@capone/common";
import { RouteComponentProps } from "react-router";

import "./styles.scss";
import { RewardsSelection } from "../RewardsSelection";
import { PaymentCardConnectorProps } from "./container";
import {
  ADD_PAYMENT_AGAIN,
  EDIT_PAYMENT_METHOD,
  UNABLED_TO_ADD_PAYMENT,
  PAYMENT_STEP_2_TITLE,
  PAYMENT_STEP_SUBTITLE,
  REWARDS_ACCOUNT_TITLE,
  REWARDS_ACCOUNT_SUBTITLE,
  CAP_ONE_INVALID_CREDIT_CARD_TITLE,
  CAP_ONE_INVALID_CREDIT_CARD_SUBTITLE,
  PAYMENT_METHOD_TITLE,
  PAYMENT_METHOD_SUBTITLE,
  CTA_SINGLE_ACCOUNT_ADD_YOUR_TEXT,
  CTA_SINGLE_ACCOUNT_CREDIT_CARD_TEXT,
  ADD_PAYMENT_METHOD_CTA_MULTIPLE_ACCOUNTS,
  ADD_PAYMENT_METHOD_MODAL_TITLE,
  ADD_ADDITIONAL_PAYMENT_METHOD_CTA,
  BACK_TO_CARD_SELECTION_CTA,
  CARD_ENDING_IN_TEXT,
  ADD_PAYMENT_FORM_HEADER_TEXT,
  MOBILE_PAYMENT_STEP_TITLE,
  MOBILE_PAYMENT_STEP_SUBTITLE,
  PAYMENT_METHOD_SUBTITLE_AMOUNT,
  TRY_AGAIN,
  INELIGIBLE_ACCOUNTS_NOTICE,
  INELIGIBLE_ACCOUNTS_TOOLTIP,
  ADD_PAYMENT_FORM_SUBTITLE_TEXT,
  PAYMENT_CARD_SUBTITLE_WITH_CREDITS_AND_OFFERS,
  PAYMENT_METHOD_SUBTITLE_EARN_REWARDS,
  PAYMENT_METHOD_SUBTITLE_MULTI_ACCOUNT,
  PAYMENT_METHOD_SUBTITLE_MULTI_ACCOUNT_INFO,
} from "./textConstants";
import "./styles.scss";
import { config } from "../../../../api/config";
import {
  useExperiments,
  getExperimentVariant,
  AVAILABLE,
  VCN_ENABLEMENT,
  CREDIT_OFFER_STACKING_V1,
  ANNUAL_TRAVEL_CREDITS,
  getExperimentVariantCustomVariants,
  TRAVEL_SALE,
  TRAVEL_SALE_VARIANTS,
  CONTROL,
} from "../../../../context/experiments";
import { TravelWalletSelection } from "../TravelWalletSelection";
import { ClientContext } from "../../../../App";

export interface IPaymentCardProps
  extends PaymentCardConnectorProps,
    RouteComponentProps {
  disabled?: boolean;
  className?: string;
  paymentStepTitle?: string;
}

export const PaymentCard = ({
  listPaymentMethods,
  verifyPaymentMethod,
  deletePaymentMethod,
  setSelectedPaymentMethodId,
  fetchRewardsAccounts,
  fetchProductToEarn,
  selectedRewardsPaymentAccount,
  selectedRewardsPaymentAccountId,
  isCreditCardPaymentRequired,
  totalCreditCardPaymentRequired,
  paymentMethods,
  rewardsAccounts,
  selectedPaymentMethodId,
  listPaymentMethodCallState,
  verifyPaymentMethodCallState,
  deletePaymentMethodCallState,
  disabled = false,
  paymentStepTitle,
  hasError,
  className,
  earnValuesByRewardAcctId,
  largestValueAccount,
  isTravelCreditPaymentOnly,
  isStackedTravelWalletPaymentOnly,
  isTravelWalletOfferPaymentOnly,
  isTravelOffersOrCreditAvailable,
  hotelBookType,
  priceFreezeOffer,
  canRedeemRewards,
  userIsPrimary,
  canUseTravelWallet,
}: IPaymentCardProps) => {
  const { matchesMobile } = useDeviceTypes();
  const [openErrorPaymentModal, setOpenErrorPaymentModal] = useState(false);
  const [isNotCapOneAccount, setIsNotCapOneAccount] = useState(false);
  const [tokenizeErrors, setTokenizeErrors] = useState<TokenizeCardErrors[]>(
    []
  );

  const expState = useExperiments();
  const { sessionInfo } = useContext(ClientContext);

  const showEarnEnhancement =
    !!largestValueAccount && !!largestValueAccount.earn.hotelsMultiplier;

  const creditAndOfferStackingExperimentV1 = getExperimentVariant(
    expState.experiments,
    CREDIT_OFFER_STACKING_V1
  );
  const isCreditAndOfferStackingExperimentV1 = useMemo(() => {
    return creditAndOfferStackingExperimentV1 === AVAILABLE;
  }, [creditAndOfferStackingExperimentV1]);

  const vcnEnablement = getExperimentVariant(
    expState.experiments,
    VCN_ENABLEMENT
  );
  const isVCNEnabled = React.useMemo(
    () => vcnEnablement === AVAILABLE,
    [vcnEnablement]
  );

  const isAnnualTravelCreditsExperiment =
    getExperimentVariant(expState.experiments, ANNUAL_TRAVEL_CREDITS) ===
    AVAILABLE;

  const travelSaleVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    TRAVEL_SALE,
    TRAVEL_SALE_VARIANTS
  );
  const isTravelSaleEnabled = travelSaleVariant !== CONTROL;

  const allowAnyCards = useExperimentIsVariant(
    "c1-corporate-credit-card-validation",
    "any-spark"
  );

  const isCorpHideTravelOffers = useExperimentIsVariant(
    "corp-hide-travel-wallet-offers",
    AVAILABLE
  );

  const isMultiAccountXpEnabled = useExperimentIsVariant(
    "corp-multi-account-fe",
    AVAILABLE
  );

  const shouldShowMultiAccountSubtitle =
    isCorpTenant(config.TENANT) && isMultiAccountXpEnabled;

  useEffect(() => {
    listPaymentMethods();
    fetchRewardsAccounts(true, sessionInfo);
  }, []);

  const ineligibleRewardsAccounts = useMemo(
    () =>
      rewardsAccounts.filter(
        (account) => !(account.allowRewardsRedemption ?? true)
      ),
    [rewardsAccounts]
  );

  const handleOnAddPaymentMethod = (token: string, last4: string) => {
    // note: cap1 specific logic:
    // A card should be deemed ineligible if the last four digits do not match one of the cards associated with their accounts.
    const account = rewardsAccounts.find(
      (account) =>
        account.lastFour === last4 ||
        account.lastFourVirtualCardNumbers?.includes(last4)
    );
    let matchingRewardsAccount = account;

    const isAddingVCNPaymentMethod = !!(
      matchingRewardsAccount?.lastFourVirtualCardNumbers &&
      matchingRewardsAccount?.lastFourVirtualCardNumbers?.includes(last4)
    );
    // TODO: bad practice, remove this in favor of real test accounts when we have them
    const isTestCard =
      window.__mclean_env__.ENV !== "production" &&
      TEST_CARD_LAST_FOURS.includes(last4);
    if (isTestCard) {
      matchingRewardsAccount = rewardsAccounts[0];
    }

    if (!!matchingRewardsAccount || allowAnyCards || isTestCard) {
      verifyPaymentMethod(
        { token },
        matchingRewardsAccount?.accountReferenceId!,
        isAddingVCNPaymentMethod
      );
    } else {
      setIsNotCapOneAccount(true);
      setOpenErrorPaymentModal(true);
    }
  };

  const handleCloseErrorPopup = () => {
    isNotCapOneAccount && setIsNotCapOneAccount(false);
    setOpenErrorPaymentModal(false);
  };

  const PaymentAddingElement = () => (
    <LoadingPopup
      indicatorSize={"small"}
      indicator={B2BSpinner}
      open={true}
      popupSize={"small"}
      message={"Adding your payment method"}
      footer={PoweredByHopper}
    />
  );

  const renderCheckoutPaymentForm = () => {
    return (
      <CheckoutPaymentForm
        loading={verifyPaymentMethodCallState === CallState.InProcess}
        loadingEl={<PaymentAddingElement />}
        onSubmit={(token, last4) => {
          handleOnAddPaymentMethod(token, last4);
        }}
        saveLabel={"Save"}
        onError={(errors) => {
          setTokenizeErrors(errors);
          setOpenErrorPaymentModal(true);
        }}
        spreedlyEnvironmentKey={config.spreedlyEnvironmentKey}
        isMobile={matchesMobile}
        className="b2b"
      />
    );
  };

  const isPaymentMethodSelectDisabled =
    selectedRewardsPaymentAccount?.isTiered ||
    !isCreditCardPaymentRequired ||
    !totalCreditCardPaymentRequired ||
    selectedRewardsPaymentAccountId === undefined;

  const paymentMethodWorkflowTitles = () => ({
    addPaymentCta:
      rewardsAccounts.length === 1 ? (
        <Box
          className={clsx(
            "add-payment-cta-container",
            {
              mobile: matchesMobile,
              disabled: disabled,
            },
            className
          )}
        >
          <Typography variant="body1">
            {CTA_SINGLE_ACCOUNT_ADD_YOUR_TEXT}
            <b className="card-name">{rewardsAccounts[0].productDisplayName}</b>
            {CTA_SINGLE_ACCOUNT_CREDIT_CARD_TEXT}
          </Typography>
          {rewardsAccounts[0].earn.hotelsMultiplier &&
          rewardsAccounts[0].earn.hotelsMultiplier > 0 ? (
            <Chip
              label={`Earn ${rewardsAccounts[0].earn.hotelsMultiplier}X on hotels`}
              className={clsx("earn-chip", {
                enabled: !isPaymentMethodSelectDisabled,
              })}
            />
          ) : null}
        </Box>
      ) : (
        ADD_PAYMENT_METHOD_CTA_MULTIPLE_ACCOUNTS
      ),
    addPaymentModalTitle: ADD_PAYMENT_METHOD_MODAL_TITLE,
    addAdditionalPaymentCta: ADD_ADDITIONAL_PAYMENT_METHOD_CTA,
    backToCardSelectionCta: BACK_TO_CARD_SELECTION_CTA,
    paymentFormHeader: (cardName: string) => (
      <Typography
        variant={"h4"}
        dangerouslySetInnerHTML={{
          __html: ADD_PAYMENT_FORM_HEADER_TEXT(cardName),
        }}
      ></Typography>
    ),
    paymentFormSubtitle: isVCNEnabled
      ? ADD_PAYMENT_FORM_SUBTITLE_TEXT
      : undefined,
    cardEndingIn: CARD_ENDING_IN_TEXT,
  });

  const rewardsTitleId = createElementId("rewardsTitle");
  const rewardsSubtitleId = createElementId("rewardsSubtitle");

  const getPaymentSubtitle = () => {
    if (shouldShowMultiAccountSubtitle) {
      return (
        <>
          {PAYMENT_METHOD_SUBTITLE_MULTI_ACCOUNT}
          <b>
            {PAYMENT_METHOD_SUBTITLE_MULTI_ACCOUNT_INFO(
              rewardsAccounts[0].productDisplayName,
              rewardsAccounts[0].lastFour,
              (sessionInfo as CorpSessionInfo).corporateInfo.businessName ?? ""
            )}
          </b>
        </>
      );
    } else if (
      rewardsAccounts[0].customerAccountRole === CustomerAccountRole.Primary
    ) {
      return PAYMENT_METHOD_SUBTITLE_EARN_REWARDS(
        rewardsAccounts[0].productDisplayName
      );
    } else {
      return (
        <>
          {!selectedPaymentMethodId && PAYMENT_METHOD_SUBTITLE}
          <b>
            {PAYMENT_METHOD_SUBTITLE_AMOUNT(totalCreditCardPaymentRequired)}
          </b>
        </>
      );
    }
  };

  const getParadiseCardMessage = () =>
    rewardsAccounts.find((acct) =>
      acct.productDisplayName.toLowerCase().includes("paradise")
    ) ? (
      <NotificationBanner
        className={clsx("paradise-banner-notification", {
          mobile: matchesMobile,
        })}
        label="Bookings made with your Paradise card through Capital One Travel cannot be serviced by concierge services. If you’d like concierge to manage your booking, book in the Capital One concierge app."
        severity={BannerSeverity.NOTICE}
      />
    ) : null;

  return (
    <>
      {listPaymentMethodCallState === CallState.InProcess ||
      deletePaymentMethodCallState === CallState.InProcess ? (
        <LoadingIndicator
          indicatorSize={"small"}
          indicator={B2BSpinner}
          message={
            listPaymentMethodCallState === CallState.InProcess
              ? `Fetching`
              : `Deleting`
          }
        />
      ) : (
        <Box
          className={clsx("payment-methods-container", {
            mobile: matchesMobile,
            disabled: disabled,
          })}
        >
          <Typography className="step-title" variant="h2">
            {paymentStepTitle ??
              (matchesMobile
                ? MOBILE_PAYMENT_STEP_TITLE(canRedeemRewards)
                : PAYMENT_STEP_2_TITLE(canRedeemRewards))}
          </Typography>
          <Typography variant="body2" className="payment-step-subtitle">
            {isCreditAndOfferStackingExperimentV1 &&
            isTravelOffersOrCreditAvailable
              ? PAYMENT_CARD_SUBTITLE_WITH_CREDITS_AND_OFFERS(
                  hotelBookType,
                  canRedeemRewards,
                  !isCorpHideTravelOffers
                )
              : matchesMobile
              ? MOBILE_PAYMENT_STEP_SUBTITLE(hotelBookType, canRedeemRewards)
              : PAYMENT_STEP_SUBTITLE(hotelBookType, canRedeemRewards)}
          </Typography>
          {canRedeemRewards && ineligibleRewardsAccounts.length > 0 && (
            <NotificationBanner
              className="authorized-users-rewards-banner"
              label={INELIGIBLE_ACCOUNTS_NOTICE(rewardsAccounts)}
              severity={BannerSeverity.NOTICE}
              tooltip={
                rewardsAccounts.length === ineligibleRewardsAccounts.length
                  ? {
                      label: INELIGIBLE_ACCOUNTS_TOOLTIP(
                        ineligibleRewardsAccounts
                      ),
                      icon: IconName.InfoCircle,
                    }
                  : undefined
              }
            />
          )}
          {(canRedeemRewards || canUseTravelWallet) && (
            <Divider className={"payment-methods-container-divider"} />
          )}
          {isCreditAndOfferStackingExperimentV1 && canUseTravelWallet && (
            <TravelWalletSelection
              disabled={disabled}
              isMobile={matchesMobile}
              isAnnualTravelCreditsExperiment={isAnnualTravelCreditsExperiment}
              isCreditAndOfferStackingExperimentV1={
                isCreditAndOfferStackingExperimentV1
              }
              isTravelSale={isTravelSaleEnabled}
            />
          )}{" "}
          {getParadiseCardMessage()}
          {canRedeemRewards && (
            <>
              <Typography variant="h3" className="rewards-account-title">
                {REWARDS_ACCOUNT_TITLE}
              </Typography>
              <Typography
                variant="body2"
                dangerouslySetInnerHTML={{ __html: REWARDS_ACCOUNT_SUBTITLE }}
                className="rewards-accounts-subtitle"
              ></Typography>
              <RewardsSelection
                {...{
                  disabled:
                    disabled ||
                    (isCreditAndOfferStackingExperimentV1 &&
                      (isTravelCreditPaymentOnly ||
                        isStackedTravelWalletPaymentOnly ||
                        isTravelWalletOfferPaymentOnly)),
                  rewardsTitleId,
                  rewardsSubtitleId,
                }}
              />
            </>
          )}
          {!canRedeemRewards && userIsPrimary && (
            <NotificationBanner
              className="out-of-policy-rewards-banner"
              severity={BannerSeverity.NOTICE}
              label={OUT_OF_POLICY_REWARDS_BANNER}
            />
          )}
          <Divider className={"payment-methods-container-divider"} />
          <Typography className="payment-method-title" variant="h3">
            {PAYMENT_METHOD_TITLE}
          </Typography>
          {!isPaymentMethodSelectDisabled && (
            <Typography variant="body2" className="payment-method-subtitle">
              {allowAnyCards && rewardsAccounts.length && getPaymentSubtitle()}
            </Typography>
          )}
          <B2BPaymentMethodSelectWorkflow
            errorModalOpen={openErrorPaymentModal}
            rewardsAccounts={rewardsAccounts ?? []}
            savedPayments={paymentMethods ?? []}
            selectedPaymentHopperId={selectedPaymentMethodId}
            disabled={
              isPaymentMethodSelectDisabled ||
              disabled ||
              hasError ||
              disabled ||
              (isCreditAndOfferStackingExperimentV1 &&
                (isTravelCreditPaymentOnly ||
                  isStackedTravelWalletPaymentOnly ||
                  isTravelWalletOfferPaymentOnly))
            }
            selectPaymentMethod={(paymentId, rewardsAccount) => {
              setSelectedPaymentMethodId({
                paymentMethodId: paymentId,
                accountId: rewardsAccount?.accountReferenceId,
                priceFreezeOffer: priceFreezeOffer,
              });
              fetchProductToEarn();
            }}
            removePaymentMethod={(paymentId: string) => {
              deletePaymentMethod({ paymentId });
            }}
            titles={paymentMethodWorkflowTitles()}
            renderCheckoutPaymentForm={renderCheckoutPaymentForm}
            isMobile={matchesMobile}
            product={TravelProductEnum.Hotels}
            loading={verifyPaymentMethodCallState === CallState.InProcess}
            buttonClassName="b2b"
            fullScreenWithBanner={matchesMobile}
            paymentMethodDisabled={(_r) => false}
            earnValuesByRewardAcctId={
              showEarnEnhancement ? earnValuesByRewardAcctId : undefined
            }
            showEarnValue={showEarnEnhancement}
            isVCNEnabled={isVCNEnabled}
            tenant={config.TENANT}
            allowAnyCards={allowAnyCards}
          />
        </Box>
      )}
      <GenericInfoPopup
        open={openErrorPaymentModal}
        image={
          <Icon
            className="error-icon"
            name={
              isNotCapOneAccount
                ? IconName.ErrorState
                : IconName.UnableToProcess
            }
          />
        }
        title={
          isNotCapOneAccount
            ? CAP_ONE_INVALID_CREDIT_CARD_TITLE
            : UNABLED_TO_ADD_PAYMENT
        }
        subtitle={
          isNotCapOneAccount
            ? CAP_ONE_INVALID_CREDIT_CARD_SUBTITLE(config.TENANT)
            : tokenizeErrors.length > 0
            ? tokenizeErrors[0].message
            : ADD_PAYMENT_AGAIN
        }
        buttons={[
          {
            buttonText: EDIT_PAYMENT_METHOD,
            onClick: () => {
              handleCloseErrorPopup();
            },
            defaultStyle: "h4r-secondary",
          },
          {
            buttonText: TRY_AGAIN,
            onClick: () => {
              handleCloseErrorPopup();
            },
            defaultStyle: "h4r-primary",
            buttonWrapperClassName: "b2b",
          },
        ]}
        isMobile={matchesMobile}
      ></GenericInfoPopup>
    </>
  );
};
