import React from "react";
import { LodgingCollectionEnum } from "redmond";
import { ensureExhaustive } from "halifax";

export const FIND_MORE_RESULTS_TITLE_TEXT = "Find more hotels";
export const FIND_MORE_RESULTS_SUBTITLE_TEXT =
  "Adjust your dates or search again to view more hotels.";
export const PREMIUM_STAYS_FIND_MORE_RESULTS_TITLE_TEXT = (
  <div style={{ lineHeight: 1.2 }}>
    <span className="font-bold">You’ve reached the end</span> of all premium
    stays in this location.
  </div>
);
export const PREMIUM_STAYS_FIND_MORE_RESULTS_SUBTITLE_TEXT = "";
export const ADJUST_DATES_TEXT = "Adjust dates";
export const RESET_FILTERS_TEXT = "Reset filters";
export const SEARCH_AGAIN_TEXT = "Search again";
export const CLEAR_FILTERS_AND_SEARCH = "Clear filters and view all hotels";
export const COUNT_HEADING_TEXT = (
  hotelCount: number,
  location: string,
  isLodgingSearch?: boolean,
  isSearchTermPoint?: boolean,
  isSearchingMap?: boolean,
  isStays?: boolean
) => (
  <>
    Viewing{" "}
    <strong>
      {hotelCount} {isStays ? "stays" : "hotels"}
    </strong>{" "}
    {isSearchingMap
      ? `in map area`
      : `${isLodgingSearch || isSearchTermPoint ? `near` : `in`} ${location}`}
  </>
);

export const PC_BENEFITS_BUTTON_TEXT = "Premier Collection Benefits";
export const LC_BENEFITS_BUTTON_TEXT = "Lifestyle Collection Benefits";

export const getEarnTagText = (earnRate: string | number, currency: string) => {
  const multiplier = currency.includes("cash") ? "%" : "X";
  return `<span class="font-bold">Earn ${earnRate}${multiplier}</span> ${currency} on hotels`;
};

export const getBenefitsSummary = (
  lodgingCollection: LodgingCollectionEnum,
  cardName?: string,
  earnMultiplier?: number,
  isUserPrimary?: boolean
) => {
  switch (lodgingCollection) {
    case LodgingCollectionEnum.Lifestyle:
      return isUserPrimary ? (
        <>
          Earn <strong>{earnMultiplier ?? "5"}X miles</strong> on your{" "}
          {cardName ?? ""} card and enjoy a{" "}
          <strong>$50 experience credit</strong> to use during your stay.
        </>
      ) : (
        <>
          Enjoy a <strong>$50 experience credit</strong> to use during your
          stay.
        </>
      );
    case LodgingCollectionEnum.Premier:
      return (
        <>
          Earn <strong>{earnMultiplier ?? "5"}X miles</strong> on your{" "}
          {cardName ?? ""} card and enjoy a{" "}
          <strong>$100 experience credit</strong> to use during your stay, daily
          breakfast for 2, and complimentary WiFi.
        </>
      );
    case LodgingCollectionEnum.NoCollection:
      return <></>;
    default:
      ensureExhaustive(lodgingCollection);
  }
};

export const getAdditionalBenefitsSummary = () => (
  <>
    Plus, enjoy other premium benefits like{" "}
    <strong>room upgrades, early check-in and late checkout</strong> when
    available
  </>
);

export const CORP_COMPARE_TO_BAR_BANNER_TEXT = (
  <>
    <strong>Save up to 10%</strong> on select hotel bookings with our corporate
    rates.
  </>
);
